import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';
import { EMPTY, Observable, switchMap, take } from 'rxjs';
import { HeaderService, PageTitleData } from './header.service';
import { PageTitleStrategy } from './page-title.strategy';

@Injectable({
  providedIn: 'root',
})
export class PageTitleUpdater {
  private readonly headerService = inject(HeaderService);
  private readonly title = inject(Title);
  private readonly pageTitleStrategy = inject(PageTitleStrategy);
  private readonly transloco = inject(TranslocoService);
  private pageTitle: PageTitleData | undefined;

  constructor() {
    // no need to unsubscribe this lifetime is same as the application
    this.transloco.langChanges$
      .pipe(
        switchMap(() => {
          // if we have page data, pass it to translate
          if (this.pageTitle) {
            return this.translatePageTitleData$(this.pageTitle);
          }

          // if no page data just ignore
          return EMPTY;
        }),
      )
      .subscribe((translatedTitle) => {
        this.updateTitle(translatedTitle);
      });
  }

  // updates the headerService and title to correct display the title
  private updateTitle(translatedTitle: string) {
    // update title with correctly retrieving the base title + the translated title
    this.title.setTitle(
      `${this.pageTitleStrategy.baseTitle} - ${translatedTitle}`,
    );

    // set title for header
    this.headerService.setTitle(translatedTitle);
  }

  // loads and translates the pageTitle data into a string
  translatePageTitleData$(pageTitle: PageTitleData): Observable<string> {
    this.setPageTitleData(pageTitle);
    // saving it locally, such that typescript doest think its possibly undefined later in the switchMap
    return this.transloco
      .load(`${pageTitle.translocoScope}/${this.transloco.getActiveLang()}`)
      .pipe(
        switchMap(() => {
          return this.transloco.selectTranslate(
            pageTitle.title,
            pageTitle.params,
          );
        }),
      );
  }

  setTranslatedPageTitleData(pageTitle: PageTitleData) {
    this.translatePageTitleData$(pageTitle)
      .pipe(take(1))
      .subscribe((translation) => {
        this.updateTitle(translation);
      });
  }

  // set pageTitle, this is used when the language changes so that this service can respond and update the titles
  setPageTitleData(pageTitle: PageTitleData) {
    this.pageTitle = pageTitle;
    this.updateTitle(pageTitle.title);
  }
}
