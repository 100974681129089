import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface PageTitleData {
  title: string;
  titleBadge?: string | null;
  params?: Record<string, unknown>;
  translocoScope?: string;
}

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private readonly _pageTitle$ = new BehaviorSubject<string | null>(null);
  private readonly _pageTitleBadge$ = new BehaviorSubject<string | null>(null);

  get pageTitle$() {
    return this._pageTitle$.asObservable();
  }

  get pageTitleBadge$() {
    return this._pageTitleBadge$.asObservable();
  }

  setTitle(title: string | PageTitleData) {
    if (typeof title === 'string') {
      this._pageTitle$.next(title);
    }
  }

  setTitleBadge(badge: string | null) {
    this._pageTitleBadge$.next(badge);
  }
}
